<template>
  <div class="page-user-center">
    <div class="banner">
      <img src="../assets/images/gerenzhongxin.jpg" alt="" width="100%">
    </div>
    <div class="user-layout">
      <div class="left-item">
        <router-link to="/userCenter/myReport" class="router-link" tag="div">我的报告</router-link>
        <router-link to="/userCenter/userQuestion" class="router-link" tag="div">个人提问</router-link>
        <router-link to="/userCenter/personInfo" class="router-link" tag="div">个人信息</router-link>
        <router-link to="/userCenter/userList" class="router-link" tag="div" v-if="userInfo.userType == 1">用户列表</router-link>
      </div>
      <div class="right-item">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'userCenter',
  computed: {
    ...mapState({
      userInfo: (state) => (state.userInfo || {})
    })
  }
}
</script>

<style scoped lang="scss">
.page-user-center{
  background-color: #fff;
  .user-layout{
    width: 1200px;
    margin: 30px auto 0;
    display: flex;
    min-height: 650px;
    .left-item{
      width: 160px;
      border: 1px solid #ddd;
      .router-link{
        line-height: 44px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        &.router-link-active {
          background-color: #2c3e50;
          color: #fff;
        }
      }
    }
    .right-item{
      flex: 1;
      border: 1px solid #ddd;
      border-left: none;
      width: 0;
    }
  }
}
</style>
